<!-- 内容管理具体详情 -->
<template>
    <div class="container">
        <content_mgt />
    </div>
</template>

<script>
import content_mgt from "@/components/task/content_mgt.vue";
export default {
    components: {
        content_mgt
    },
    data() {
        return {
        }
    },
    created() { },
    mounted() { },
    watch: {},
    computed: {},
    methods: {},
}
</script>
<style lang="scss" scoped>
.container {
    padding: 1rem 2rem;
    box-sizing: border-box;
}
</style>