<!-- 内容管理 -->
<template>
  <div class="container">
    <div class="contentmgt">
      <el-row>
        <!-- <el-button size="mini" @click="centerDialogVisible = true">添加内容</el-button> -->
        <el-button size="mini" @click="del">批量删除</el-button>
        <el-button size="mini" @click="batchUp">批量通过</el-button>
        <el-button size="mini" @click="allocationDialogVisible = true"
          >批量分配</el-button
        >
        <span class="search">
          <template>
            内容状态：
            <el-select
              size="mini"
              style="width: 120px; margin-right: 10px"
              v-model="status"
              clearable
              placeholder="请选择内容状态"
              @change="getpromptcontent(true)"
            >
              <el-option label="未编辑" :value="0"> </el-option>
              <el-option label="已编辑" :value="1"> </el-option>
              <el-option label="可用" :value="2"> </el-option>
              <el-option label="已分配" :value="3"> </el-option>
            </el-select>
          </template>
        </span>
      </el-row>
      <el-table
        small
        :fit="true"
        ref="multipleTable"
        :data="tableData"
        :key="tableKey"
        style="
          width: calc(100% - 14px);
          font-size: 0.8rem;
          flex: 1;
          margin-top: 10px;
        "
        v-loading="loading"
        height="100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          label="序号"
          type="index"
          width="80"
          align="center"
          :show-overflow-tooltip="true"
        >
        </el-table-column>

        <el-table-column
          label="任务名称"
          :show-overflow-tooltip="true"
          width="100"
        >
          <template slot-scope="scope">
            {{ scope.row.missionTitle }}
          </template>
        </el-table-column>
        <el-table-column
          label="模板名称"
          width="120"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.promptName }}
          </template>
        </el-table-column>
        <el-table-column
          label="当前状态"
          width="100"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.status === 0"> 未编辑 </span>
            <span v-else-if="scope.row.status === 2"> 可用 </span>
            <span v-else-if="scope.row.status === 1"> 已编辑 </span>
            <span v-else-if="scope.row.status === 3"> 已分配 </span>
          </template>
        </el-table-column>
        <el-table-column
          label="录入类型"
          width="100"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.type === 0"> AI生成 </span>
            <span v-else-if="scope.row.type === 1">人工录入 </span>
          </template>
        </el-table-column>
        <el-table-column label="密令词" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            {{
              scope.row.commentImplantation
                ? scope.row.commentImplantation
                : "\\"
            }}
          </template>
        </el-table-column>
        <el-table-column label="内容">
          <template slot-scope="scope">
            <span
              style="
                overflow: hidden;
                text-overflow: ellipsis;
                -o-text-overflow: ellipsis;
                white-space: nowrap;
                width: 100%;
                display: block;
              "
            >
              {{ scope.row.response }}</span
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="120">
          <template slot-scope="scope">
            <span class="handel" @click="editcontent(scope.row)">编辑内容</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        small
        class="pagination"
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="getpromptcontent"
        :current-page.sync="pageNo"
        :page-size="pageSize"
      >
      </el-pagination>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      title="提示"
      :visible.sync="contentDialogVisible"
      width="30%"
      center
    >
      <div slot="title" class="header-title">
        <span class="name" style="color: black; font-size: 0.8rem"
          >编辑内容</span
        >
      </div>
      <div>
        <el-input
          type="textarea"
          :rows="2"
          class="response"
          style="margin-bottom: 10px"
          resize="none"
          placeholder="请输入需要编辑的标题"
          v-model="editData.title"
        >
        </el-input>
        <el-input
          type="textarea"
          :rows="15"
          class="response"
          resize="none"
          placeholder="请输入编辑的内容"
          v-model="editData.response"
        >
        </el-input>
        <el-input
          type="textarea"
          :rows="2"
          class="response"
          style="margin-top: 10px"
          resize="none"
          placeholder="请输入需要编辑的评论植入"
          v-model="editData.commentImplantation"
        >
        </el-input>
        <el-input
          type="textarea"
          :rows="2"
          class="response"
          style="margin-top: 10px"
          resize="none"
          placeholder="请输入需要编辑的回复内容"
          v-model="editData.commentContent"
        >
        </el-input>
        <el-upload
          :class="{ hide: hideUploadAdd }"
          style="margin-top: 10px"
          size="mini"
          :limit="9"
          multiple
          :file-list="editData.fileList"
          action="/api/cos/upload"
          list-type="picture-card"
          :on-success="successChange"
          :on-change="handleAddChange"
          :on-preview="handlePictureCardPreview"
          :headers="uploadHeaders"
          :data="{ folder: 'social' }"
          :on-remove="handleRemove"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-row style="text-align: center; margin-top: 10px">
          <el-button size="mini" @click="submitContent(1)">确认修改</el-button>
          <el-button
            size="mini"
            @click="submitContent(2)"
            v-if="editData.type !== 1"
            >确认可用</el-button
          >
        </el-row>
      </div>
    </el-dialog>
    <el-dialog
      title="批量分配"
      :visible.sync="allocationDialogVisible"
      width="30%"
      center
    >
      <div slot="title" class="header-title">
        <span class="name" style="color: black; font-size: 0.8rem"
          >批量分配文案</span
        >
      </div>
      <div>
        <el-form label-position="right" label-width="80px" size="mini">
          <el-form-item label="选择任务">
            <el-select
              style="width: 100%"
              v-loadmore="loadMore"
              clearable
              filterable
              placeholder="请选择任务"
              remote
              v-model="allocationId"
            >
              <el-option
                :key="index"
                :label="item.title"
                :value="item.id"
                v-for="(item, index) in allprompts"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-row style="text-align: center; margin-top: 10px">
          <el-button size="mini" @click="allocation">确定分配</el-button>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getpromptList,
  saveprompt,
  zbMissionPromptRecord,
  updatePromptRecord,
  del,
  batchUp,
  distribute,
  getList,
} from "@/api/home";
import store from "@/store";
import { positiveinteger } from "@/util/checkFun.js";
import { loadmore } from "@/config/directives";
export default {
  components: {},
  directives: {
    loadmore,
  },
  data() {
    return {
      centerDialogVisible: false,
      contentDialogVisible: false,
      allocationDialogVisible: false,
      // 搜索
      promptId: null,
      status: null,
      //   模板内容

      contentData: {
        amount: 10,
        content: "",
        missionId: this.missionId,
        promptId: null,
      },
      //   内容表格数据
      tableData: [],
      tableKey: false,
      pageNo: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      //   编辑弹窗数据
      editData: {
        id: null,
        response: "",
        type: null,
        fileList: [],
        commentImplantation: "",
        commentContent: "",
        title: "",
      },
      // 多选内容
      multipleSelection: [],
      // 上传图片相关
      hideUploadAdd: false,
      uploadHeaders: { "x-auth-token": localStorage.getItem("token") },
      allocationId: null,
      allprompts: [],
      promptpageNo: 1,
    };
  },
  created() {},
  async mounted() {
    await this.getpromptcontent();
    this.getList();
  },
  watch: {},
  computed: {
    missionId() {
      return store.state.task.taskInfo.missionId;
    },
  },
  methods: {
    // 验证整数方法
    positiveinteger() {
      this.contentData.amount = positiveinteger(this.contentData.amount);
    },
    // 品牌方创建模板
    generate() {
      saveprompt({
        saveQoList: [
          {
            amount: this.contentData.amount,
            content: this.contentData.content,
            missionId: this.missionId,
            promptId: this.contentData.promptId,
          },
        ],
      }).then((res) => {
        if (res.code === 1000) {
          this.$message({
            message: "成功创建模板,请耐心等待生成内容",
            type: "success",
          });
          this.centerDialogVisible = false;
          this.getpromptcontent();
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 获取生成内容
    async getpromptcontent(val) {
      if (val === true) {
        this.pageNo = 1;
      }
      await zbMissionPromptRecord({
        missionId: this.missionId,
        pageNo: this.pageNo,
        pageSize: 10,
        status: this.status,
      }).then((res) => {
        this.tableData = res.data.records;
        this.tableKey = !this.tableKey;
        this.total = res.data.total;
      });
    },
    // 编辑内容
    editcontent(val) {
      this.contentDialogVisible = true;
      this.editData.id = val.id;
      this.editData.response = val.response;
      this.editData.status = val.status;
      this.editData.commentImplantation = val.commentImplantation;
      this.editData.commentContent = val.commentContent;
      this.editData.title = val.title;
      this.handleimg("object", val.imgUrls.split(",") || []);

      this.editData.type = val.type;
    },
    submitContent(STATUS) {
      updatePromptRecord({
        id: this.editData.id,
        response: this.editData.response,
        status: STATUS,
        type: this.editData.type,
        commentImplantation: this.editData.commentImplantation,
        imgUrlList: this.handleimg("array", this.editData.fileList),
        commentContent: this.editData.commentContent,
        title: this.editData.title,
      }).then((res) => {
        if (res.code === 1000) {
          this.contentDialogVisible = false;
          this.getpromptcontent();
          this.$message({
            message: "修改成功",
            type: "success",
          });
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val.map((res) => {
        return res.id;
      });
    },
    // 批量操作
    async del() {
      if (this.multipleSelection.length === 0) {
        this.$message({
          message: "请选择批量删除模板",
          type: "warning",
        });
      } else {
        await del({ ids: this.multipleSelection }).then((res) => {
          if (res.code === 1000) {
            this.$message({
              message: "删除成功",
              type: "success",
            });
            this.getpromptcontent();
          }
        });
      }
    },
    async batchUp() {
      if (this.multipleSelection.length === 0) {
        this.$message({
          message: "请选择批量通过模板",
          type: "warning",
        });
      } else {
        await batchUp({ ids: this.multipleSelection, status: 2 }).then(
          (res) => {
            if (res.code === 1000) {
              this.$message({
                message: "通过成功",
                type: "success",
              });
              this.getpromptcontent();
            }
          }
        );
      }
    },
    // 批量分配
    async allocation() {
      if (this.multipleSelection.length === 0) {
        this.$message({
          message: "请选择批量分配模板",
          type: "warning",
        });
      } else {
        await distribute({
          promptRecordIds: this.multipleSelection,
          missionId: this.allocationId,
        }).then((res) => {
          if (res.code === 1000) {
            this.$message({
              message: "分配成功",
              type: "success",
            });
            this.allocationDialogVisible = false;
            this.getpromptcontent();
          }
        });
      }
    },
    handleimg(type, data) {
      if (type === "array") {
        let newdata = this.editData.fileList.map((res) => {
          return res.url;
        });
        return newdata;
      } else {
        this.editData.fileList = data.map((res) => {
          return {
            url: res,
          };
        });
      }
    },
    // 上传图片
    handleRemove(file, fileList) {
      this.editData.fileList = this.editData.fileList.filter(function (item) {
        return item.url !== file.url;
      });

      if (fileList.length <= 9) {
        this.hideUploadAdd = false;
      }
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 上传change事件
    handleAddChange(file, fileList) {
      // 图片大小限制
      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$message.error("上传图片大小不能超过 20MB!");
        // 发现某一张超过大小就从列表移除
        fileList.splice(-1, 1);
      }
      // 上传文件>=限制个数时隐藏上传组件
      if (fileList.length >= 9) {
        this.hideUploadAdd = true;
      }
    },
    async successChange(response, file, fileList) {
      this.editData.fileList.push({ url: response.data });
    },
    // 搜索内容管理新增内容中已有的任务
    async getList() {
      await getList({ pageNo: this.promptpageNo, pageSize: 20 }).then((res) => {
        if (res.data.records.length > 0) {
          this.allprompts = [...this.allprompts, ...res.data.records];
        } else {
          this.alloadMore = false;
        }
      });
    },
    // 加载更多
    loadMore() {
      if (this.alloadMore) {
        this.promptpageNo = this.promptpageNo + 1;
        this.getList();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .el-form-item__label {
  font-size: 0.8rem;
}

.container {
  height: 100%;
  color: black;

  .response {
    white-space: pre-wrap;
    font-size: 0.8rem;

    ::v-deep .el-textarea__inner {
      background-color: #f7f6f4;
    }
  }

  .status {
    margin-top: 10px;
    text-align: right;
    font-size: 0.8rem;
    color: #999999;

    & span {
      margin-right: 10px;
    }

    ::v-deep .el-radio__label {
      font-size: 0.8rem;
      color: #999999;
    }

    ::v-deep .el-radio__inner {
      width: 0.8rem;
      height: 0.8rem;
    }
  }
}

.contentmgt {
  height: 100%;
  display: flex;
  flex-direction: column;

  .search {
    float: right;
    font-size: 0.8rem;
  }

  .pagination {
    margin-top: 10px;
    margin-left: auto;
  }
}
</style>